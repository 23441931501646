<template>
  <div>
    <div>
      <KTPortlet title="View Network Groups">
        <template v-slot:toolbar>
          <v-btn v-if="groups !== null" color="success" @click="addGroup()"
            >ADD GROUP</v-btn
          >
          <!-- <div class="dropdown dropdown-inline">
            <button
              type="button"
              class="btn btn-clean btn-sm btn-icon btn-icon-md"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="flaticon2-settings"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right dropdown-menu-fit dropdown-menu-md"
            >
              <div style="padding: 20px;">
                <b-form-group label="View">
                  <b-form-radio v-model="showTreeView" size="lg" :value="false"
                    >Table view</b-form-radio
                  >
                  <b-form-radio v-model="showTreeView" size="lg" :value="true"
                    >Hierarchical view</b-form-radio
                  >
                </b-form-group>
              </div>
            </div>
          </div> -->
        </template>
        <template slot="body">
          <Loader v-if="groups === null" :inContent="true">Loading...</Loader>
          <div
            v-else-if="groups.length === 0"
            style="width: 100%; text-align: center"
          >
            No groups to display
          </div>
          <b-table-simple v-else responsive>
            <b-thead>
              <b-tr>
                <b-th sticky-column>Name</b-th>
                <b-th sticky-column>Auto Adopt</b-th>
                <b-th></b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="group in groups" v-bind:key="group.id">
                <b-th sticky-column>{{ group.name }}</b-th>
                <b-th sticky-column>{{ group.autoAdopt ? "YES" : "NO" }}</b-th>
                <b-td>
                  <b-button
                    style="color: #fff"
                    @click="editGroup(group)"
                    variant="info"
                    >EDIT GROUP</b-button
                  >
                  <b-button
                    @click="setupGroup(group.id)"
                    variant="success"
                    style="margin-left: 10px; color: #fff"
                    >SETUP DEVICES</b-button
                  >
                  <b-button
                    style="margin-left: 10px; color: #fff"
                    @click="$router.push(`/Networks/${$route.params.serviceId}/Devices?groupfilter=${group.id}`)"
                    variant="success"
                    >VIEW DEVICES</b-button
                  >
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </template>
      </KTPortlet>
    </div>
    <YSIDEBAR ref="addEditGroup" @hide="sidebarHidden">
      <template slot="header" v-if="!$tools.isNullOrUndefined(editGroupObject)">
        <h3
          style="padding: 0 20px 0 20px"
          v-if="!$tools.isNullOrUndefined(editGroupObjectRef)"
        >
          Configure {{ editGroupObjectRef.name || "New group" }}
        </h3>
        <h3 style="padding: 0 20px 0 20px" v-else>Add new group</h3>
      </template>
      <Loader
        v-if="
          editGroupObjectSaving === true ||
          $tools.isNullOrUndefined(editGroupObject)
        "
        :inContent="true"
        >Loading...</Loader
      >
      <v-form
        ref="addEditForm"
        v-model="editGroupObjectValid"
        lazy-validation
        v-else-if="!$tools.isNullOrUndefined(editGroupObject)"
      >
        <div style="padding: 20px">
          <v-text-field
            v-model="editGroupObject.name"
            type="text"
            label="Name"
            :rules="editGroupObjectNameRules"
            :required="true"
          ></v-text-field>
          <v-checkbox
            v-model="editGroupObject.autoAdopt"
            label="Auto Adopt"
            hint="When the device connects, we will auto adopt the device"
            :persistent-hint="true"
          ></v-checkbox>
          <div style="width: 10px; height: 30px"></div>
          <h5>
            Maintenance Windows
            <div
              @click="addNewMaintWindow()"
              style="
                margin-left: 10px;
                width: 30px;
                height: 30px;
                display: inline-block;
                background: var(--info);
                flex: none;
                text-align: center;
                vertical-align: middle;
                line-height: 30px;
                border-radius: 50%;
                cursor: pointer;
              "
            >
              <v-icon style="color: #fff">mdi-plus</v-icon>
            </div>
          </h5>
          <template v-if="!$tools.isNullOrUndefined(editGroupObjectRef)">
          </template>
          <template v-else>
            <div>
              <div style="display: inline-block; width: 100%">
                <!-- <v-autocomplete
                  v-if="!$tools.isNullOrUndefined(templates)"
                  :items="getTemplates"
                  item-text="name"
                  item-value="value"
                  v-model="editGroupObject.gatewayId"
                  xplaceholder="Start typing to Search"
                ></v-autocomplete> -->
              </div>
              <div
                style="display: inline-block; margin-left: 5px"
                v-if="editGroupObject.gatewayId !== null"
              >
                <b-btn @click="editGroupObject.gatewayId = null" small text
                  >X</b-btn
                >
              </div>
            </div>
          </template>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(item, i) in editGroupObject.maintWindows"
              :key="i"
            >
              <v-expansion-panel-header>
                <v-chip
                  v-if="fieldsetValid(i) !== null"
                  class="ma-2"
                  :style="`background: var(--danger); color: #fff;`"
                  >{{ fieldsetValid(i) }} is invalid</v-chip
                >{{ item.name }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field
                  :ref="`editGroupObjectRefValueName-${i}`"
                  v-model="item.name"
                  type="text"
                  label="Name"
                  :rules="editGroupObjectNameRules"
                  :required="true"
                  @sblur="
                    () =>
                      $nextTick(() => {
                        item.nameValid =
                          $refs[`editGroupObjectRefValueName-${i}`].valid;
                      })
                  "
                  @ssblur="
                    () =>
                      $nextTick(() => {
                        window.xx = $refs[`editGroupObjectRefValueName-${i}`];
                      })
                  "
                ></v-text-field>
                <v-select
                  :ref="`editGroupObjectRefValueTypes-${i}`"
                  v-model="item.types"
                  :items="maintWindowTypeField"
                  attach
                  chips
                  label="Change types"
                  multiple
                  item-text="name"
                  item-value="value"
                  :rules="editGroupObjectTypesRules"
                  :required="true"
                ></v-select>

                <v-combobox
                  :ref="`editGroupObjectRefValueTimezone-${i}`"
                  v-model="item.timezoneS"
                  :items="timezonesFields"
                  attach
                  label="Timezone"
                  item-text="name"
                  item-value="value"
                  :rules="editGroupObjectTimezoneRules"
                  :required="true"
                ></v-combobox>
                <v-container>
                  <v-row>
                    <v-col cols="6" md="6" style="padding: 0">
                      <v-text-field
                        :ref="`editGroupObjectRefValueStartTime-${i}`"
                        label="Start Time"
                        v-model="item.startTime"
                        type="time"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="6" style="padding: 0">
                      <v-text-field
                        :ref="`editGroupObjectRefValueEndTime-${i}`"
                        label="End Time"
                        v-model="item.endTime"
                        type="time"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>

                <v-select
                  :ref="`editGroupObjectRefValueDaysOfWeek-${i}`"
                  v-model="item.daysOfWeek"
                  :items="maintWindowDaysField"
                  attach
                  chips
                  label="Days of the week"
                  multiple
                  item-text="name"
                  item-value="value"
                  :rules="editGroupObjectTypesRules"
                  :required="true"
                ></v-select>
                <v-select
                  :ref="`editGroupObjectRefValueDaysOfMonth-${i}`"
                  v-model="item.daysOfMonth"
                  :items="maintWindowDaysOfMonthField"
                  attach
                  chips
                  label="Days of the month"
                  multiple
                  item-text="name"
                  item-value="value"
                  :rules="editGroupObjectTypesRules"
                  :required="true"
                ></v-select>
                <div style="width: 10px; height: 30px"></div>
                <b-button
                  :disabled="editGroupObject.maintWindows.length <= 1"
                  style="float: right; margin-right: 20px"
                  @click="editGroupObject.maintWindows.splice(i, 1)"
                  variant="danger"
                  >REMOVE</b-button
                >
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div style="width: 10px; height: 30px"></div>
          <b-button
            style="margin-right: 20px"
            :disabled="!editGroupObjectValid"
            @click="saveAddEditGroup"
            :variant="editGroupObjectValid ? 'success' : 'warning'"
            >SAVE</b-button
          >
          <div style="width: 10px; height: 100px"></div>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span
                  style="display: inline-block; flex: none; margin-right: 5px"
                  ><v-icon style="color: var(--info); text-align: left"
                    >mdi-help-circle</v-icon
                  >
                </span>
                Help</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <h6>Maintenance Window Types:</h6>
                <p
                  v-for="typ in maintWindowTypeField"
                  v-bind:key="typ.value"
                  style="padding-left: 5px"
                >
                  <b>{{ typ.name }}</b
                  ><br />
                  {{ typ.spig }}
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div style="width: 10px; height: 50px"></div>
          <!-- <b-button
            style="margin-right: 20px"
            :disabled="removeDeviceBusy"
            @click="removeDevice()"
            variant="danger"
            >{{
              removeDeviceBusy
                ? "WAIT!"
                : removeDeviceConfirm
                ? "ARE YOU SURE?"
                : "REMOVE"
            }}</b-button
          > -->
        </div>
      </v-form>
    </YSIDEBAR>
    <GROUPSETUP
      ref="groupSetup"
      @hide="setupSidebarHidden"
      @newGroup="newGroupEvent"
      :emitGroupEvent="true"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import Swal from "sweetalert2";
import TLib from "./lib";
import Y_SIDEBAR from "../../partials/layout/QuickPanelBase";
import GROUP_SETUP from "./group_setup";
import { rawTimeZones } from "@vvo/tzdb";

export default {
  components: {
    YSIDEBAR: Y_SIDEBAR,
    GROUPSETUP: GROUP_SETUP,
    KTPortlet,
    Loader,
  },
  watch: {},
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      gateways: null,
      groups: null,
      editGroupObject: null,
      editGroupObjectRef: null,
      editGroupObjectSaving: false,
      editGroupObjectValid: false,
      maintWindowCounter: 0,
    };
  },
  methods: {
    newGroupEvent() {
      this.addGroup();
    },
    fieldsetValid(index) {
      let fields = [
        "Name",
        "Types",
        "Timezone",
        "StartTime",
        "EndTime",
        "DaysOfWeek",
        "DaysOfMonth",
      ];
      for (let field of fields) {
        if (
          this.$tools.isNullOrUndefined(
            this.$refs[`editGroupObjectRefValue${field}-${index}`]
          )
        )
          return null;
        if (
          this.$tools.isNullOrUndefined(
            this.$refs[`editGroupObjectRefValue${field}-${index}`][0]
          )
        )
          return null;
        if (!this.$refs[`editGroupObjectRefValue${field}-${index}`][0].valid)
          return field;
      }
      return null;
    },
    saveAddEditGroup() {
      this.$data.editGroupObjectSaving = true;
      if (!this.$refs.addEditForm.validate()) {
        this.$data.editGroupObjectSaving = false;
        return;
      }
      for (let i = 0; i < this.$data.editGroupObject.maintWindows.length; i++) {
        if (
          !this.$tools.isNullOrUndefined(
            this.$data.editGroupObject.maintWindows[i].timezoneS
          )
        )
          this.$data.editGroupObject.maintWindows[i].timezone =
            this.$data.editGroupObject.maintWindows[i].timezoneS.tName;

        if (
          !this.$tools.isNullOrUndefined(
            this.$data.editGroupObject.maintWindows[i].startTime
          )
        ) {
          let startTimeSt =
            this.$data.editGroupObject.maintWindows[i].startTime.split(":");
          this.$data.editGroupObject.maintWindows[i].startS =
            (Number.parseInt(startTimeSt[0]) * 60 +
              Number.parseInt(startTimeSt[1])) *
            60;
        }
        if (
          !this.$tools.isNullOrUndefined(
            this.$data.editGroupObject.maintWindows[i].endTime
          )
        ) {
          let endTimeSt =
            this.$data.editGroupObject.maintWindows[i].endTime.split(":");
          this.$data.editGroupObject.maintWindows[i].endS =
            (Number.parseInt(endTimeSt[0]) * 60 +
              Number.parseInt(endTimeSt[1])) *
            60;
          if (this.$data.editGroupObject.maintWindows[i].endS === 0) {
            this.$data.editGroupObject.maintWindows[i].endS = 24 * 60 * 60;
          }
        }
      }
      const self = this;
      TLib.addUpdateGroup(
        this,
        this.$route.params.serviceId,
        (this.editGroupObjectRef || {}).id || undefined,
        this.editGroupObject
      )
        .then(() => {
          self.updateData(true);
          self.$data.editGroupObjectSaving = false;
          self.$refs.addEditGroup.hide();
          //self.$log.info(x);
        })
        .catch((x) => {
          self.$log.error(x);
        });
    },
    sidebarHidden() {
      this.$router.push(`/Networks/${this.$route.params.serviceId}/Groups`);
    },
    addNewMaintWindow() {
      let arrOfDays = [];
      for (let i = 0; i < 25; i++) arrOfDays.push(i);
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      for (let tZone of this.timezonesFields) {
        if (tZone.tName === timezone) {
          timezone = tZone;
          break;
        }
      }
      this.$data.maintWindowCounter++;
      this.$data.editGroupObject.maintWindows.push({
        name:
          this.editGroupObject.maintWindows.length === 0
            ? "Default maintenance window"
            : `Maintenance window ${this.maintWindowCounter}`,
        timezone: timezone.tName,
        timezoneS: timezone,
        daysOfWeek: [2, 3, 4, 5, 6],
        daysOfMonth: arrOfDays,
        startTime: "00:00",
        startS: 0, //00:00
        endTime: "06:00",
        endS: 21600, //06:00
        types: this.maintWindowTypes,
      });
    },
    addGroup() {
      this.$refs.groupSetup.hide();
      this.$router.push(
        `/Networks/${this.$route.params.serviceId}/Groups?action=add`
      );
      this._addGroup();
    },
    _addGroup() {
      this.$data.editGroupObject = {
        name: "",
        autoAdopt: true,
        maintWindows: [],
      };
      this.$data.maintWindowCounter = 0;
      this.addNewMaintWindow();
      this.$data.editGroupObjectSaving = false;
      this.$data.editGroupObjectValid = false;
      this.$refs.addEditGroup.show();
    },
    editGroup(group) {
      this.$router.push(
        `/Networks/${this.$route.params.serviceId}/Groups?action=edit&group=${group.id}`
      );
      this._editGroup(group);
    },
    _editGroup(group) {
      this.$data.editGroupObject = JSON.parse(JSON.stringify(group));
      for (let i = 0; i < this.$data.editGroupObject.maintWindows.length; i++) {
        let sStart = `${
          this.$data.editGroupObject.maintWindows[i].startS / 60 / 60
        }`.split(".");
        if (sStart.length == 1) sStart.push("0");
        let mStart = (
          (60 * Number.parseInt(sStart[1].substring(0, 2)) * 99) /
          100
        )
          .toFixed(0)
          .substring(0, 2);
        this.$data.editGroupObject.maintWindows[i].startTime = `${
          sStart[0].length == 1 ? "0" : ""
        }${sStart[0]}:${mStart.length == 1 ? "0" : ""}${mStart}`;

        let sEnd = `${
          this.$data.editGroupObject.maintWindows[i].endS / 60 / 60
        }`.split(".");
        if (sEnd.length == 1) sEnd.push("0");
        let mEnd = ((60 * Number.parseInt(sEnd[1].substring(0, 2)) * 99) / 100)
          .toFixed(0)
          .substring(0, 2);
        if (sEnd[0] == "24") sEnd[0] = "00";
        this.$data.editGroupObject.maintWindows[i].endTime = `${
          sEnd[0].length == 1 ? "0" : ""
        }${sEnd[0]}:${mEnd.length == 1 ? "0" : ""}${mEnd}`;

        for (let tZone of this.timezonesFields) {
          if (
            tZone.tName == this.$data.editGroupObject.maintWindows[i].timezone
          ) {
            this.$data.editGroupObject.maintWindows[i].timezoneS = tZone;
            break;
          }
        }
      }
      this.$data.editGroupObjectRef = group;
      this.$data.maintWindowCounter = group.maintWindows.length;
      this.$data.editGroupObjectSaving = false;
      this.$data.editGroupObjectValid = false;
      this.$refs.addEditGroup.show();
    },
    setupSidebarHidden() {
      this.$router.push(`/Networks/${this.$route.params.serviceId}/Groups`);
    },
    setupGroup(groupId) {
      this.$router.push(
        `/Networks/${this.$route.params.serviceId}/Groups?action=setup&group=${groupId}`
      );
      this._setupGroup(groupId);
    },
    _setupGroup(groupId) {
      this.$refs.groupSetup.show(groupId);
    },
    updateData() {
      let self = this;
      return new Promise((resolve, reject) => {
        self.$data.groups = null;
        TLib.getGroups(self, self.$route.params.serviceId)
          .then((data) => {
            TLib.getGateways(self, self.$route.params.serviceId)
              .then((data) => {
                self.$data.gateways = data;
                resolve();
              })
              .catch((x) => {
                self.$log.error(x);
                reject(x);
                //console.error("Error loading content");
              });
            for (let i = 0; i < data.length - 1; i++) {
              for (let j = i + 1; j < data.length; j++) {
                if (data[i].name > data[j].name) {
                  let tA = data[j];
                  data[j] = data[i];
                  data[i] = tA;
                }
              }
            }
            self.$data.groups = data;
          })
          .catch((x) => {
            self.$log.error(x);
            reject(x);
            //console.error("Error loading content");
          });
      });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },

    maintWindowTypes() {
      return ["reboot", "upgrade", "configurationChange", "other"];
    },
    maintWindowTypeField() {
      return [
        {
          value: "reboot",
          name: "Reboot",
          spig: "The device is allowed to be rebooted during this period.",
        },
        {
          value: "upgrade",
          name: "Upgrade",
          spig: "The device is allowed to be upgraded during this period.",
        },
        {
          value: "configurationChange",
          name: "Configuration Change",
          spig: "Configurations can be deployed during this period.",
        },
        { value: "other", name: "Other", spig: "Other things" },
      ];
    },
    maintWindowDaysOfMonthField() {
      let days = [];
      for (let i = 0; i < 28; i++)
        days.push({
          name: `${i < 9 ? "0" : ""}${i + 1}`,
          value: i,
        });
      return days;
    },
    maintWindowDaysField() {
      return [
        {
          name: "Sunday",
          value: 0,
        },
        {
          name: "Monday",
          value: 1,
        },
        {
          name: "Tuesday",
          value: 2,
        },
        {
          name: "Wednesday",
          value: 3,
        },
        {
          name: "Thursday",
          value: 4,
        },
        {
          name: "Friday",
          value: 5,
        },
        {
          name: "Saturday",
          value: 6,
        },
      ];
    },
    timezonesFields() {
      let returnList = [];
      const getTZInfo = (offset) => {
        let o = Math.abs(offset);
        return (
          (offset < 0 ? "-" : "+") +
          ("00" + Math.floor(o / 60)).slice(-2) +
          "." +
          ("00" + (o % 60)).slice(-2)
        );
      };

      for (let timeZ of rawTimeZones) {
        returnList.push({
          name: `(UTC${getTZInfo(timeZ.rawOffsetInMinutes)}) ${timeZ.name}`,
          tName: timeZ.name,
          raw: timeZ.rawOffsetInMinutes,
          value: timeZ.name,
        });
      }

      for (let i = 0; i < returnList.length - 1; i++) {
        for (let j = i + 1; j < returnList.length; j++) {
          if (returnList[i].raw > returnList[j].raw) {
            let tA = returnList[j];
            returnList[j] = returnList[i];
            returnList[i] = tA;
          }
        }
      }

      return returnList;
    },

    editGroupObjectNameRules() {
      let self = this;
      let listORules = [];
      listORules.push((v) => {
        if (self.$tools.isNullOrUndefined(v) || v === "")
          return `Field is required`;
        let minLength = 2;
        if (`${v}`.length < minLength)
          return `${minLength - `${v}`.length} more characters required`;
        let maxLength = 250;
        if (`${v}`.length > maxLength)
          return `${`${v}`.length - maxLength} too many characters`;
        return null;
      });
      return [
        (v) => {
          for (let rule of listORules) {
            let result = rule(v);
            if (!self.$tools.isNull(result)) return result;
          }
          return true;
        },
      ];
    },
    editGroupObjectTimezoneRules() {
      let self = this;
      let listORules = [];
      listORules.push((v) => {
        if (self.$tools.isNullOrUndefined(v) || v === "")
          return `Timezone is required`;
        if (self.$tools.isNullOrUndefined(v.value) || v.value === "")
          return `Please select a valid timezone`;
        /*let minLength = 2;
        if (`${v}`.length < minLength)
          return `${minLength - `${v}`.length} more characters required`;
        let maxLength = 250;
        if (`${v}`.length > maxLength)
          return `${`${v}`.length - maxLength} too many characters`;*/
        return null;
      });
      return [
        (v) => {
          for (let rule of listORules) {
            let result = rule(v);
            if (!self.$tools.isNull(result)) return result;
          }
          return true;
        },
      ];
    },
    editGroupObjectTypesRules() {
      let self = this;
      let listORules = [];
      listORules.push((v) => {
        if (self.$tools.isNullOrUndefined(v) || v === "")
          return `Field is required`;
        if (!self.$tools.isArray(v)) return "Need at least one";
        if (v.length < 1) return "Need 1 or more.";
        return null;
      });
      return [
        (v) => {
          for (let rule of listORules) {
            let result = rule(v);
            if (!self.$tools.isNull(result)) return result;
          }
          return true;
        },
      ];
    },
  },
  beforeDestroy() {
    this.$eventBus.off(`switch-service-selected`);
    this.$eventBus.off(`ws-networks-tr069-group-updated`);
    this.$eventBus.off(`ws-networks-tr069-group-added`);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Networks" },
      { title: "Gateways" },
    ]);
    let self = this;

    this.$eventBus.on(`switch-service-selected`, () => self.updateData());
    this.$eventBus.on(`ws-networks-tr069-group-updated`, () => self.updateData(true));
    this.$eventBus.on(`ws-networks-tr069-group-added`, () => self.updateData(true));

    this.updateData().then(() => {
      if (this.$route.query.action === "add") {
        return self._addGroup();
      }
      if (this.$route.query.action === "setup") {
        return self._setupGroup(self.$route.query.group);
      }
    });
  },
};
</script>
